import React from "react"
import SEO from "../components/seo";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import {Link, Typography} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";

const Contact = () => (
  <React.Fragment>
    <SEO title="Kontakt"/>
    <Container maxWidth="md">
      <Box my={1}>
        <Card>
          <Box m={1}>
            <Typography variant="h3">
              Kontaktdaten
            </Typography>
            <Table small>
              <TableBody>
                <TableRow>
                  <TableCell>Anschrift</TableCell>
                  <TableCell>
                    Günter Schnaitl <br/>
                    Nissensttraße 32 <br/>
                    5020 Salzburg
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Telefon</TableCell>
                  <TableCell>
                    <Link href="tel:+43 660 1801091" color="secondary">+43 660 1801091</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>E-Mail</TableCell>
                  <TableCell>
                    <Link href="mailto:schnaitl@gmx.at" color="secondary">schnaitl@gmx.at</Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Card>
      </Box>
    </Container>
  </React.Fragment>
)

export default Contact
